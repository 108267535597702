export default defineAppConfig({
  ui: {
    primary: "teal",
    gray: "grey",
    formGroup: {
      label: {
        base: "text-pumpkin dark:text-pumpkin uppercase font-bold",
      },
      description: "text-white dark:text-white",
    },
    "::selection": {},
    input: {
      form: "",
      placeholder: "text-white font-bold",
      rounded: "rounded-none",
      padding: {
        xs: "py-1.5",
        sm: "py-1.5",
      },
      variant: {
        outline: "text-white bg-black/60 dark:bg-black/60 backdrop-blur-sm",
      },
      color: {
        white: {
          outline:
            "border-b-[1px] text-white border-teal bg-black/60 dark:bg-black/60 backdrop-blur-sm",
        },
      },
    },
    textarea: {
      form: "",
      placeholder: "text-white font-bold",
      rounded: "rounded-none",
      variant: {
        outline: "text-white bg-black/60 dark:bg-black/60 backdrop-blur-sm",
      },
      color: {
        white: {
          outline:
            "border-[1px] text-white border-teal bg-black/60 dark:bg-black/60 backdrop-blur-sm",
        },
      },
    },
    checkbox: {
      rounded: "rounded-none",
      label: "text-white dark:text-white",
      wrapper:
        "border-[1px] border-teal items-center p-4 bg-black/60 dark:bg-black/60 backdrop-blur-sm",
    },
    select: {
      placeholder: "text-white font-bold",
      rounded: "rounded-none",
      padding: {
        xs: "py-1.5",
        sm: "py-1.5",
      },
      variant: {
        outline: "text-white bg-black/60 dark:bg-black/60 backdrop-blur-sm",
      },
      color: {
        white: {
          outline:
            "text-white border-b-[1px] border-teal bg-black/60 dark:bg-black/60 backdrop-blur-sm",
        },
      },
    },
    button: {
      rounded: "rounded-none",
      variant: {
        outline:
          "text-{color} dark:text-{color} bg-black/60 dark:bg-black/60 backdrop-blur-sm ring-teal dark:ring-teal",
      },
    },
    alert: {
      variant: {
        solid: "bg-{color} dark:bg-{color} text-white dark:text-gray",
        outline:
          "text-{color} dark:text-{color} ring-1 ring-inset ring-{color}-500 dark:ring-{color}",
        soft: "bg-{color} dark:bg-{color} dark:bg-opacity-10 text-{color} dark:text-{color}",
        subtle:
          "bg-{color} dark:bg-{color} dark:bg-opacity-10 text-{color} dark:text-{color} ring-1 ring-inset ring-{color} dark:ring-{color} ring-opacity-25 dark:ring-opacity-25",
      },
    },
  },
});
