//  https://uicolors.app/create
export const AccentColors = {
  pumpkin: {
    "50": "#fef6ee",
    "100": "#feecd6",
    "200": "#fcd4ac",
    "300": "#f9b678",
    "400": "#f68d41",
    "500": "#f37123",
    "600": "#e45312",
    "700": "#bd3d11",
    "800": "#963216",
    "900": "#792b15",
    "950": "#411309",
    DEFAULT: "#f37123", //500
  },
  teal: {
    "50": "#f0fdfc",
    "100": "#cdfaf9",
    "200": "#9af5f2",
    "300": "#60e6e8",
    "400": "#2fcbd2",
    "500": "#16adb6",
    "600": "#0e808a",
    "700": "#106c75",
    "800": "#12555d",
    "900": "#14474d",
    "950": "#05282e",
    DEFAULT: "#0e808a", // 600
  },
  violet: {
    "50": "#eef0ff",
    "100": "#e0e2ff",
    "200": "#c7cafe",
    "300": "#a5a7fc",
    "400": "#8781f8",
    "500": "#7563f1",
    "600": "#6646e5",
    "700": "#5838ca",
    "800": "#4830a3",
    "900": "#392b78",
    "950": "#251b4b",
    DEFAULT: "#392B78", // 900
  },
  scarlet: {
    "50": "#fff0f3",
    "100": "#ffdce3",
    "200": "#ffc0cc",
    "300": "#ff94a8",
    "400": "#ff5676",
    "500": "#ff224c",
    "600": "#ff0232",
    "700": "#d80029",
    "800": "#b90225",
    "900": "#930923",
    "950": "#51000f",
    DEFAULT: "#B90225", // 800
  },
  patina: {
    "50": "#effaf4",
    "100": "#d7f4e3",
    "200": "#b2e8cb",
    "300": "#80d5ac",
    "400": "#4cbb8a",
    "500": "#29a06e",
    "600": "#1b8059",
    "700": "#176e4e",
    "800": "#13523b",
    "900": "#114332",
    "950": "#08261d",
    DEFAULT: "#176e4e", // 700
  },
} as const;

export const Colors = {
  inherit: "inherit",
  current: "currentColor",
  transparent: "transparent",
  trueblack: "#000",
  black: "#231F20",
  white: "#FFF",
  nightblue: {
    "50": "#eef8ff",
    "100": "#dcf1ff",
    "200": "#b2e4ff",
    "300": "#6dcfff",
    "400": "#20b7ff",
    "500": "#009eff",
    "600": "#007ddf",
    "700": "#0063b4",
    "800": "#005495",
    "900": "#00457a",
    "950": "#00172b",
    DEFAULT: "#00172b", // 950
  },
  grey: {
    "50": "#f5f5f6",
    "100": "#e6e6e7",
    "200": "#d0d0d1",
    "300": "#afb0b1",
    "400": "#86878a",
    "500": "#6e6f72",
    "600": "#5b5c5f",
    "700": "#4e4f50",
    "800": "#444446",
    "900": "#3c3c3d",
    "950": "#252527",
    DEFAULT: "#6e6f72", // 500
  },
  sand: {
    "50": "#fbf6f1",
    "100": "#f5eadf",
    "200": "#edd7c4",
    "300": "#deb493",
    "400": "#d08e67",
    "500": "#c5734a",
    "600": "#b85f3e",
    "700": "#994b35",
    "800": "#7b3e31",
    "900": "#64352a",
    "950": "#351a15",
    DEFAULT: "#edd7c4", // 200
  },
  red: {
    400: AccentColors.scarlet.DEFAULT,
    500: AccentColors.scarlet.DEFAULT,
  },
  ...AccentColors,
} as const;
