const _inherit = "inherit"
const _current = "currentColor"
const _transparent = "transparent"
const _trueblack = "#000"
const _black = "#231F20"
const _white = "#FFF"
const _nightblue = {"50":"#eef8ff","100":"#dcf1ff","200":"#b2e4ff","300":"#6dcfff","400":"#20b7ff","500":"#009eff","600":"#007ddf","700":"#0063b4","800":"#005495","900":"#00457a","950":"#00172b","DEFAULT":"#00172b"}
const _grey = {"50":"#f5f5f6","100":"#e6e6e7","200":"#d0d0d1","300":"#afb0b1","400":"#86878a","500":"#6e6f72","600":"#5b5c5f","700":"#4e4f50","800":"#444446","900":"#3c3c3d","950":"#252527","DEFAULT":"#6e6f72"}
const _sand = {"50":"#fbf6f1","100":"#f5eadf","200":"#edd7c4","300":"#deb493","400":"#d08e67","500":"#c5734a","600":"#b85f3e","700":"#994b35","800":"#7b3e31","900":"#64352a","950":"#351a15","DEFAULT":"#edd7c4"}
const _red = {"400":"#B90225","500":"#B90225"}
const _pumpkin = {"50":"#fef6ee","100":"#feecd6","200":"#fcd4ac","300":"#f9b678","400":"#f68d41","500":"#f37123","600":"#e45312","700":"#bd3d11","800":"#963216","900":"#792b15","950":"#411309","DEFAULT":"#f37123"}
const _teal = {"50":"#f0fdfc","100":"#cdfaf9","200":"#9af5f2","300":"#60e6e8","400":"#2fcbd2","500":"#16adb6","600":"#0e808a","700":"#106c75","800":"#12555d","900":"#14474d","950":"#05282e","DEFAULT":"#0e808a"}
const _violet = {"50":"#eef0ff","100":"#e0e2ff","200":"#c7cafe","300":"#a5a7fc","400":"#8781f8","500":"#7563f1","600":"#6646e5","700":"#5838ca","800":"#4830a3","900":"#392b78","950":"#251b4b","DEFAULT":"#392B78"}
const _scarlet = {"50":"#fff0f3","100":"#ffdce3","200":"#ffc0cc","300":"#ff94a8","400":"#ff5676","500":"#ff224c","600":"#ff0232","700":"#d80029","800":"#b90225","900":"#930923","950":"#51000f","DEFAULT":"#B90225"}
const _patina = {"50":"#effaf4","100":"#d7f4e3","200":"#b2e8cb","300":"#80d5ac","400":"#4cbb8a","500":"#29a06e","600":"#1b8059","700":"#176e4e","800":"#13523b","900":"#114332","950":"#08261d","DEFAULT":"#176e4e"}
const _primary = {"50":"rgb(var(--color-primary-50) / <alpha-value>)","100":"rgb(var(--color-primary-100) / <alpha-value>)","200":"rgb(var(--color-primary-200) / <alpha-value>)","300":"rgb(var(--color-primary-300) / <alpha-value>)","400":"rgb(var(--color-primary-400) / <alpha-value>)","500":"rgb(var(--color-primary-500) / <alpha-value>)","600":"rgb(var(--color-primary-600) / <alpha-value>)","700":"rgb(var(--color-primary-700) / <alpha-value>)","800":"rgb(var(--color-primary-800) / <alpha-value>)","900":"rgb(var(--color-primary-900) / <alpha-value>)","950":"rgb(var(--color-primary-950) / <alpha-value>)","DEFAULT":"rgb(var(--color-primary-DEFAULT) / <alpha-value>)"}
const _gray = {"50":"rgb(var(--color-gray-50) / <alpha-value>)","100":"rgb(var(--color-gray-100) / <alpha-value>)","200":"rgb(var(--color-gray-200) / <alpha-value>)","300":"rgb(var(--color-gray-300) / <alpha-value>)","400":"rgb(var(--color-gray-400) / <alpha-value>)","500":"rgb(var(--color-gray-500) / <alpha-value>)","600":"rgb(var(--color-gray-600) / <alpha-value>)","700":"rgb(var(--color-gray-700) / <alpha-value>)","800":"rgb(var(--color-gray-800) / <alpha-value>)","900":"rgb(var(--color-gray-900) / <alpha-value>)","950":"rgb(var(--color-gray-950) / <alpha-value>)"}
const config = { "inherit": _inherit, "current": _current, "transparent": _transparent, "trueblack": _trueblack, "black": _black, "white": _white, "nightblue": _nightblue, "grey": _grey, "sand": _sand, "red": _red, "pumpkin": _pumpkin, "teal": _teal, "violet": _violet, "scarlet": _scarlet, "patina": _patina, "primary": _primary, "gray": _gray,  }
export { config as default, _inherit, _current, _transparent, _trueblack, _black, _white, _nightblue, _grey, _sand, _red, _pumpkin, _teal, _violet, _scarlet, _patina, _primary, _gray }