
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexfx05q8xInVMeta } from "/usr/src/app/pages/_tools/rendering/index.vue?macro=true";
import { default as sizesv6onajmZaDMeta } from "/usr/src/app/pages/_tools/rendering/sizes.ts?macro=true";
import { default as _91_46_46_46slug_93pvGVZEAWcBMeta } from "/usr/src/app/pages/[...slug].vue?macro=true";
export default [
  {
    name: "_tools-rendering___nl",
    path: "/_tools/rendering",
    meta: indexfx05q8xInVMeta || {},
    component: () => import("/usr/src/app/pages/_tools/rendering/index.vue")
  },
  {
    name: "_tools-rendering___en",
    path: "/en/_tools/rendering",
    meta: indexfx05q8xInVMeta || {},
    component: () => import("/usr/src/app/pages/_tools/rendering/index.vue")
  },
  {
    name: "_tools-rendering-sizes___nl",
    path: "/_tools/rendering/sizes",
    component: () => import("/usr/src/app/pages/_tools/rendering/sizes.ts")
  },
  {
    name: "_tools-rendering-sizes___en",
    path: "/en/_tools/rendering/sizes",
    component: () => import("/usr/src/app/pages/_tools/rendering/sizes.ts")
  },
  {
    name: "slug___nl",
    path: "/:slug(.*)*",
    component: () => import("/usr/src/app/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/usr/src/app/pages/[...slug].vue")
  }
]