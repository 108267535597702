<script setup>
import BackgroundRendering from "~/components/three/BackgroundRendering.vue";

const router = useRouter();
const magnoliaContext = useMagnoliaContext();

router.afterEach((to, from) => {
  if (to.path === from.path) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
});

router.beforeResolve((to, from, next) => {
  // Disable routing when in edit mode
  if (magnoliaContext.isMagnoliaEdit) {
    const loadingIndicator = useLoadingIndicator();
    loadingIndicator.clear();
    return false;
  }
  return next();
});

const color = "var(--color-pumpkin)";
</script>

<template>
  <NuxtLoadingIndicator :color></NuxtLoadingIndicator>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
<style lang="scss">
:root {
  --transition-duration: 0.3s;
  --transition-easing: ease-in-out;
  @media (prefers-reduced-motion: reduce) {
    --transition-duration: 0s;
  }
}
.page-enter-active,
.page-leave-active {
  transition: all var(--transition-duration) var(--transition-easing);
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  //transform: scale(2);
  filter: blur(3px);
}
</style>
