import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoFacebook, LazySvgoInstagram, LazySvgoLinkedIn, LazySvgoMonogramWhite, LazySvgoTRIMMLogo, LazySvgoTwitterX, LazySvgoAccolladeLeftIcon, LazySvgoAccolladeRightIcon, LazySvgoAlert, LazySvgoBurgerIcon, LazySvgoContrastIcon, LazySvgoCrossIcon, LazySvgoDownArrow, LazySvgoEmailIcon, LazySvgoLeftChevron, LazySvgoMinusIcon, LazySvgoPlusIcon, LazySvgoRightArrow, LazySvgoRightChevron, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["SvgoFacebook", LazySvgoFacebook],
["SvgoInstagram", LazySvgoInstagram],
["SvgoLinkedIn", LazySvgoLinkedIn],
["SvgoMonogramWhite", LazySvgoMonogramWhite],
["SvgoTRIMMLogo", LazySvgoTRIMMLogo],
["SvgoTwitterX", LazySvgoTwitterX],
["SvgoAccolladeLeftIcon", LazySvgoAccolladeLeftIcon],
["SvgoAccolladeRightIcon", LazySvgoAccolladeRightIcon],
["SvgoAlert", LazySvgoAlert],
["SvgoBurgerIcon", LazySvgoBurgerIcon],
["SvgoContrastIcon", LazySvgoContrastIcon],
["SvgoCrossIcon", LazySvgoCrossIcon],
["SvgoDownArrow", LazySvgoDownArrow],
["SvgoEmailIcon", LazySvgoEmailIcon],
["SvgoLeftChevron", LazySvgoLeftChevron],
["SvgoMinusIcon", LazySvgoMinusIcon],
["SvgoPlusIcon", LazySvgoPlusIcon],
["SvgoRightArrow", LazySvgoRightArrow],
["SvgoRightChevron", LazySvgoRightChevron],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
